"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviredService = void 0;
var axios_1 = require("axios");
var fulfillment_service_1 = require("./fulfillment-service");
var PreviredService = /** @class */ (function (_super) {
    __extends(PreviredService, _super);
    function PreviredService(kongService, traceService, previredUrl) {
        var _this = _super.call(this, kongService, traceService) || this;
        _this.kongService = kongService;
        _this.traceService = traceService;
        _this.previredUrl = previredUrl;
        _this.previredUrlV2 = "".concat(_this.previredUrl.replace(/\/+$/, ""), "/api/v2");
        _this.previredUrl = "".concat(_this.previredUrl.replace(/\/+$/, ""), "/api/v1");
        return _this;
    }
    PreviredService.getJWTAutorization = function (previredUrl, advisoryCode, apiKey) {
        return __awaiter(this, void 0, void 0, function () {
            var processedUrl, url, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        processedUrl = "".concat(previredUrl.replace(/\/+$/, ""), "/api/v1");
                        url = "".concat(processedUrl, "/advisory-code/").concat(advisoryCode, "?apikey=").concat(apiKey);
                        return [4 /*yield*/, axios_1.default.get(url)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    PreviredService.prototype.validateAssisted = function (requestPayload) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrl, "/validate/assisted");
                return [2 /*return*/, this.postWithTrace(url, requestPayload, "validateAssisted")];
            });
        });
    };
    PreviredService.prototype.getToken = function (transactionId, extraData) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrl, "/token?transactionId=").concat(transactionId);
                url += extraData ? "&extraData=".concat(extraData) : '';
                return [2 /*return*/, this.getWithTrace(url, "getToken")];
            });
        });
    };
    PreviredService.prototype.validateRemoteAssisted = function (requestPayload) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrl, "/validation/remote-assisted");
                return [2 /*return*/, this.postWithTrace(url, requestPayload, "validateRemoteAssisted")];
            });
        });
    };
    PreviredService.prototype.validateRemoteAssistedEnded = function (validationData, transactionId) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrl, "/validation/remote-assisted/").concat(transactionId, "/client-authentication");
                return [2 /*return*/, this.postWithTrace(url, validationData, "validateRemoteAssistedEnded")];
            });
        });
    };
    PreviredService.prototype.saveOTIData = function (requestOTIData, transactionId) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrlV2, "/validation/remote-assisted/").concat(transactionId, "/oti-data");
                return [2 /*return*/, this.putWithTrace(url, requestOTIData, "saveOTIData")];
            });
        });
    };
    PreviredService.prototype.selfValidation = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrlV2, "/validate/self");
                return [2 /*return*/, this.postWithTrace(url, request, "selfValidation")];
            });
        });
    };
    PreviredService.prototype.validateRemoteAssistedEndedWithCertificate = function (request, transactionId) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrlV2, "/validation/remote-assisted/").concat(transactionId, "/client-authentication");
                return [2 /*return*/, this.postWithTrace(url, request, "validateRemoteAssistedEnded")];
            });
        });
    };
    PreviredService.prototype.validateRemoteAssistedCICUEEnded = function (request, transactionId) {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = "".concat(this.previredUrlV2, "/validation/remote-assisted/").concat(transactionId, "/client-auth-stack");
                return [2 /*return*/, this.postWithTrace(url, request, "validateRemoteAssistedEnded")];
            });
        });
    };
    return PreviredService;
}(fulfillment_service_1.FulfillmentService));
exports.PreviredService = PreviredService;
