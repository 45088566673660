<template>
  <div class="previred-remote-asisted">
    <ProgressMark circle circle-line-top :status="getFirstPMStatus()">
      <div class="sw-step">
        {{ isExecutiveValidated() ? "Ejecutivo validado exitosamente" : "" }}
      </div>
    </ProgressMark>
    <ProgressMark circle-line-bottom :status="getFirstPMStatus()">
      <div v-if="showAuthWidget && !hasErrors" class="auth">
        <div v-if="hasErrors">{{ errorMessage }}</div>
        <FulfillmentAuthentication v-if="isInit()" :rut="executiveRut" :oti-mode="true" :method="'fingerprint'"
          :button-enabled="true" :button-label="'Validar ejecutivo'" :dummy-mode="dummyMode" :trace-id="traceId"
          :kong-token="kongToken" :kong-client-id="kongClientId" :kong-client-secret="kongClientSecret"
          :environment="environment" :channel-key="channelKey" @auth="onExecutiveAuthentication">
        </FulfillmentAuthentication>
      </div>
      <Spinner v-if="!showAuthWidget && !advisoryCode && !hasErrors" />
      <div v-if="!showAuthWidget && advisoryCode && !hasErrors">
        <p class="advisory-code-message">
          Para continuar, indícale al cliente que ingrese el siguiente código en el sitio web de
          Cuprum:
        </p>
        <p class="advisory-code">
          <strong>{{ advisoryCode }}</strong>
        </p>
      </div>
    </ProgressMark>
    <ProgressMark circle circle-line-top :status="getLastPMStatus()">
      <div class="sw-step">
        <div class="remote-assisted" v-if="isExecutiveValidated()">
          <div v-if="isPollingOn && !hasErrors">
            <span>{{ pollingTitle }}</span>
          </div>
          <div v-if="hasErrors">{{ errorTitle }}: Por seguridad no es posible continuar.</div>
          <div v-if="isClientValidated()">Cliente válidado con éxito.</div>
        </div>
      </div>
    </ProgressMark>
  </div>
</template>

<script>
import ProgressMark, { checkStatusMethods, statuses } from "@/components/ProgressMark";
import { VALIDATION } from "@/services/previred-api";
import { IPUtils } from "@/services/previred/ip-utils";
import { FFResultEvent } from "@/services/previred/structure-response";
import FulfillmentAuthentication from "fulfillment-cuprum-auth-widget";
import Spinner from "./Spinner";


const TRACE_STEP = "PreviredCICUERemoteAssisted";
const RESULT_EVENT = "previredCICUERemoteAssisted";
const AUTH_METHOD = {
  CICUE: "CICUE"
};
export const REMOTE_ASSISTED_STATUS = {
  INIT: "INIT",
  EXECUTIVE_VALIDATED: "EXECUTIVE_VALIDATED",
  CLIENT_VALIDATED: "CLIENT_VALIDATED"
};

export default {
  name: TRACE_STEP,
  components: { FulfillmentAuthentication, Spinner, ProgressMark },
  props: {
    rut: { type: String, required: true },
    executiveRut: { type: String, required: true },
    dummyMode: { type: Boolean, default: false },
    color: { type: String, default: "#0091DA" },
    authMethod: { type: String },
    kongToken: { type: String },
    kongClientId: { type: String },
    kongClientSecret: { type: String },
    previredService: { type: Object, required: true },
    traceService: { type: Object, required: true },
    traceId: { type: String, required: true },
    environment: { type: String },
    channelKey: { type: String },
    clientEmail: { type: String },
    timeoutCi: {
      type: Number,
      required: true,
      validator: function (value) {
        alert(value)
        return 1 <= value && value <= 600
      }
    },
    timeoutCue: {
      type: Number,
      required: true,
      validator: function (value) {
        alert(value)
        return 1 <= value && value <= 600
      }
    },
    strictNameValidation: { type: Boolean, default: false }
  },
  data() {
    return {
      showAuthWidget: true,
      isPollingOn: false,
      pollingIntervalInMillis: parseInt(process.env.VUE_APP_PREVIRED_POLLING_INTERVAL),
      pollingErrorRetries: parseInt(process.env.VUE_APP_PREVIRED_POLLING_ERROR_THRESHOLD),
      pollingTitle: undefined,
      hasErrors: false,
      errorTitle: "Error al procesar la solicitud",
      authTransactionId: "",
      advisoryCode: undefined,
      tokenStatus: undefined,
      currentRemoteStatus: REMOTE_ASSISTED_STATUS.INIT
    };
  },
  methods: {
    ...checkStatusMethods,
    isInit() {
      return REMOTE_ASSISTED_STATUS.INIT === this.currentRemoteStatus;
    },
    isExecutiveValidated() {
      return REMOTE_ASSISTED_STATUS.INIT !== this.currentRemoteStatus;
    },
    isClientValidated() {
      return REMOTE_ASSISTED_STATUS.CLIENT_VALIDATED === this.currentRemoteStatus;
    },
    getFirstPMStatus() {
      switch (this.currentRemoteStatus) {
        case REMOTE_ASSISTED_STATUS.INIT:
          return this.hasErrors ? statuses.ERROR : statuses.DOING;
        default:
          return statuses.DONE;
      }
    },
    getLastPMStatus() {
      switch (this.currentRemoteStatus) {
        case REMOTE_ASSISTED_STATUS.INIT:
          return statuses.TODO;
        case REMOTE_ASSISTED_STATUS.EXECUTIVE_VALIDATED:
          return this.hasErrors ? statuses.ERROR : statuses.DOING;
        default:
          return statuses.DONE;
      }
    },
    remoteAssistedValidationRequest(authResponse, myIP) {
      const request = myIP ? { executiveIP: myIP } : {};
      const validationInfo =
        this.authMethod && this.authMethod === AUTH_METHOD.CICUE
          ? {
            validationType: VALIDATION.CICUE.validationType,
            provider: VALIDATION.CICUE.provider,
            authMethod: VALIDATION.CICUE.authMethod
          }
          : {};
      return {
        executiveValidation: {
          validationType: VALIDATION.ACEPTA_BIOMETRIC.validationType,
          provider: VALIDATION.ACEPTA_BIOMETRIC.provider,
          data: {
            rut: authResponse.result.userId,
            name: authResponse.result.name,
            lastName: authResponse.result.lastName,
            motherLastName: authResponse.result.motherLastName,
            auditCode: authResponse.result.auditCode,
            url: authResponse.result.resultUrl
          }
        },
        remoteValidationInfo: {
          clientRut: this.rut,
          clientEmail: this.clientEmail,
          channelKey: this.channelKey,
          timeoutCi: this.timeoutCi,
          timeoutCue: this.timeoutCue,
          strictNameValidation: this.strictNameValidation,
          ...validationInfo,
          ...request,
        }
      };
    },
    onExecutiveAuthentication(authResponse) {
      this.showAuthWidget = false;
      const traceStep = `${TRACE_STEP}.onExecutiveAuthentication`;
      if (authResponse.result.userId !== this.executiveRut) {
        this.errorMessage = "Error durante la validación del ejecutivo";
        this.hasErrors = true;
        const errorReason = "Unexpected userId on AuthWidget response";
        this.traceService.error(traceStep, errorReason, JSON.stringify(authResponse));
        const errorResponse = {
          reason: "UNEXPECTED_USERID_AUTHWIDGET_RESPONSE",
          message: `UserId: ${authResponse.result.userId}  / ExecutiveRut: ${this.executiveRut} `
        };
        this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
      } else if (authResponse.status !== "OK") {
        this.errorMessage = "Error durante la validación del ejecutivo";
        this.hasErrors = true;
        const errorReason = "Executive authentication failed";
        this.traceService.error(traceStep, errorReason, JSON.stringify(authResponse));
        const errorResponse = {
          reason: "EXECUTIVE_AUTHENTICATION_FAILED",
          message: `Status Auth Response: ${authResponse.status}`
        };
        this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
      } else {
        this.currentRemoteStatus = REMOTE_ASSISTED_STATUS.EXECUTIVE_VALIDATED;
        return IPUtils.getBrowserIp()
          .then(resp => {
            const remoteAssistedValidationReq = this.remoteAssistedValidationRequest(
              authResponse,
              resp
            );
            return this.previredService
              .validateRemoteAssisted(remoteAssistedValidationReq)
              .then(response => {
                this.pollingTitle = "Validación del cliente en proceso";
                this.advisoryCode = response.advisoryCode;
                this.pollToken(response.transactionId, Date.now());
              })
              .catch(error => {
                this.errorMessage = "Error iniciando autenticación remota del cliente";
                this.hasErrors = true;
                const errorResponse = {
                  reason: "CLIENT_AUTHENTICATION_INIT_FAILURE",
                  message: JSON.stringify(error)
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              });
          })
          .catch(() => {
            const remoteAssistedValidationReq = this.remoteAssistedValidationRequest(authResponse);
            return this.previredService
              .validateRemoteAssisted(remoteAssistedValidationReq)
              .then(response => {
                this.pollingTitle = "Validación del cliente en proceso";
                this.advisoryCode = response.advisoryCode;
                this.pollToken(response.transactionId, Date.now());
              })
              .catch(error => {
                this.errorMessage = "Error iniciando autenticación remota del cliente";
                this.hasErrors = true;
                const errorResponse = {
                  reason: "CLIENT_AUTHENTICATION_INIT_FAILURE",
                  message: JSON.stringify(error)
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              });
          });
      }
    },
    pollToken(transactionId, startTime) {
      let errorReason = "ERROR_PROCESSIONG_REQUEST";
      this.isPollingOn = true;
      let timePassed = Date.now() - startTime;
      if (timePassed < process.env.VUE_APP_PREVIRED_OTI_REMOTE_POLLING_MAX_TIME) {
        setTimeout(() => {
          this.previredService
            .getToken(transactionId)
            .then(response => {
              const lastTokenStatus = this.tokenStatus;
              this.tokenStatus = response.status;
              if (this.tokenStatus === "UNCONFIRMED") {
                this.pollToken(transactionId, startTime);
              } else if (this.tokenStatus === "PENDING") {
                this.pollingTitle = "El cliente ha sido validado";
                if (this.dummyMode) {
                  this.isPollingOn = false;
                  this.tokenStatus = "OK";
                  this.currentRemoteStatus = REMOTE_ASSISTED_STATUS.CLIENT_VALIDATED;
                  const dummyResponse = {
                    transactionId: transactionId,
                    token:
                      "dummy.WxsbWVudC5hdXRoLWFwaSIsImlhdCI6MTYyNTc3NTkyNCwiZXhwIjoxNjI1Nzc2MjI0LCJzY29wZXMiOltdLCJ",
                    clientIP: "0.0.0.0",
                    executiveIP: "0.0.0.1",
                    clientProvider: "TOC"
                  };
                  this.$emit(RESULT_EVENT, FFResultEvent.success(dummyResponse, this.traceId));
                } else {
                  this.pollToken(transactionId, startTime);
                }
              } else if (this.tokenStatus === "OK") {
                this.isPollingOn = false;
                this.currentRemoteStatus = REMOTE_ASSISTED_STATUS.CLIENT_VALIDATED;
                const structuredResponse = {
                  transactionId: transactionId,
                  token: response.token,
                  clientIP: response.clientIP,
                  executiveIP: response.executiveIP,
                  clientProvider: response.clientProvider
                };
                this.$emit(RESULT_EVENT, FFResultEvent.success(structuredResponse, this.traceId));
              } else {
                if (this.tokenStatus === "EXPIRED") {
                  this.errorMessage = "El tiempo para concretar la operación expiró";
                  errorReason = "EXPIRED_TIME_TO_COMPLETE_OPERATION";
                } else if (lastTokenStatus === "UNCONFIRMED") {
                  this.errorMessage = "Error en la validación del cliente";
                  errorReason = "ERROR_IN_CLIENT_VALIDATION";
                } else {
                  this.errorMessage = "Error al procesar la solicitud";
                }
                this.hasErrors = true;
                this.isPollingOn = false;
                const errorResponse = {
                  reason: "ERROR_OBTAINING_PREVIRED_TOKEN",
                  message: `Status in token response :  ${response.status}`
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              }
            })
            .catch(error => {
              this.pollingErrorRetries -= 1;
              if (this.pollingErrorRetries > 0) {
                this.pollToken(transactionId, startTime);
              } else {
                this.errorMessage = "Error al procesar la solicitud";
                this.hasErrors = true;
                this.isPollingOn = false;
                const errorResponse = {
                  reason: "ERROR_OBTAINING_PREVIRED_TOKEN",
                  message: `Status in token response :  ${error.message}`
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              }
            });
        }, this.pollingIntervalInMillis);
      } else {
        this.errorMessage = "Tiempo de espera maximo excedido";
        this.hasErrors = true;
        this.isPollingOn = false;
        const errorResponse = {
          reason: errorReason,
          message: "Polling exceeds the maximum time allowed"
        };
        this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
      }
    }
  }
};
</script>

<style scoped>
.sw-step {
  align-items: center;
  height: 100%;
  width: 100%;
  color: #484848;
  font-size: 0.9rem;
}

.errors {
  color: red;
}

.advisory-code-message {
  font-size: 0.9em;
  color: #353535;
  max-width: 23em;
  margin-bottom: 0;
}

.advisory-code {
  margin-top: 0.25em;
}

img.hourglass {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

img.success {
  animation: expand 2s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-10deg);
  }

  20% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes expand {
  0% {
    transform: width 110%, height 110%;
  }

  50% {
    transform: width 110%, height 110%;
  }

  100% {
    transform: width 110%, height 110%;
  }
}
</style>