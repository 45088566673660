"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FFResultEvent = exports.FFResultStatus = void 0;
var FFResultStatus;
(function (FFResultStatus) {
    FFResultStatus["OK"] = "OK";
    FFResultStatus["NOK"] = "NOK";
})(FFResultStatus = exports.FFResultStatus || (exports.FFResultStatus = {}));
var FFResultEvent = /** @class */ (function () {
    function FFResultEvent() {
    }
    FFResultEvent.success = function (result, traceId) {
        return { result: result, traceId: traceId, status: FFResultStatus.OK };
    };
    FFResultEvent.failure = function (error, traceId) {
        return { error: error, traceId: traceId, status: FFResultStatus.NOK };
    };
    return FFResultEvent;
}());
exports.FFResultEvent = FFResultEvent;
