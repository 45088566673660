const LogLevel = {
  TRACE: 5,
  DEBUG: 4,
  INFO: 3,
  WARN: 2,
  ERROR: 1,
}

export const createLogger = (name) => {
  const logLevel = LogLevel[process.env.VUE_APP_LOG_LEVEL] || LogLevel.DEBUG
  return {
    trace: (...msgs) => logLevel >= LogLevel.TRACE ? console.trace(`[${name}]`, ...msgs) : undefined,
    log: (...msgs) => logLevel >= LogLevel.DEBUG ? console.debug(`[${name}]`, ...msgs) : undefined,
    debug: (...msgs) => logLevel >= LogLevel.DEBUG ? console.debug(`[${name}]`, ...msgs) : undefined,
    info: (...msgs) => logLevel >= LogLevel.INFO ? console.info(`[${name}]`, ...msgs) : undefined,
    warn: (...msgs) => logLevel >= LogLevel.WARN ? console.warn(`[${name}]`, ...msgs) : undefined,
    error: (...msgs) => logLevel >= LogLevel.ERROR ? console.error(`[${name}]`, ...msgs) : undefined,
  }
}