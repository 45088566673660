import { default as uuidv4 } from 'uuid-random';

export const DEVELOPMENT = "development";
export const QA = "qa";
export const PRODUCTION = "production";

export default class Config {
  static buildEnvironment() {
    return process.env.VUE_APP_BUILD_ENVIRONMENT;
  }

  static isProdAllowed() {
    return Config.buildEnvironment() === PRODUCTION;
  }

  static isQAAllowed() {
    return Config.buildEnvironment() === QA || Config.buildEnvironment() === PRODUCTION;
  }

  static isDevAllowed() {
    return true;
  }

  static allowedEnvironments() {
    let envs = [DEVELOPMENT];
    if (this.isQAAllowed()) envs.push(QA);
    if (this.isProdAllowed()) envs.push(PRODUCTION);
    return envs;
  }

  static createTraceId() {
    const uuid = uuidv4()
    return uuid;
  }

  constructor(environment) {
    function throwForbiddenEnvError() {
      const error = `Forbidden environment ${environment}`;
      const cause = `This build was targeted for ${Config.buildEnvironment()}`;
      const moreInfo = `the following envs are allowed: ${Config.allowedEnvironments()}`;
      throw new Error(`${error}. ${cause}, ${moreInfo}`);
    }
    if (environment === PRODUCTION) {
      if (!Config.isProdAllowed()) throwForbiddenEnvError();
      this.environment = environment;
    } else if (environment === QA) {
      if (!Config.isQAAllowed()) throwForbiddenEnvError();
      this.environment = environment;
    } else if (environment === DEVELOPMENT) {
      this.environment = environment;
    } else {
      console.warn(`Unknown environment ${environment}, using default: ${DEVELOPMENT}`);
      this.environment = DEVELOPMENT;
    }
  }

  isProd() {
    return this.environment === PRODUCTION;
  }

  isQA() {
    return this.environment === QA;
  }

  isDev() {
    return !this.isProd() && !this.isQA();
  }

  kongUrl() {
    if (this.isProd()) return process.env.VUE_APP_OAUTH_GET_TOKEN_API_PRINCIPAL_URL_PROD;
    else if (this.isQA()) return process.env.VUE_APP_OAUTH_GET_TOKEN_API_PRINCIPAL_URL_QA;
    return process.env.VUE_APP_OAUTH_GET_TOKEN_API_PRINCIPAL_URL_DEV;
  }

  kafkaRestUrl() {
    if (this.isProd()) return process.env.VUE_APP_FULFILLMENT_TRACE_API_PROD;
    else if (this.isQA()) return process.env.VUE_APP_FULFILLMENT_TRACE_API_QA;
    return process.env.VUE_APP_FULFILLMENT_TRACE_API_DEV;
  }

  previredApiUrl() {
    if (this.isProd()) return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_PROD;
    else if (this.isQA()) return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_QA;
    return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_DEV;
  }

  previredUrl() {
    if (this.isProd()) return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_JWT;
    else if (this.isQA()) return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_JWT;
    else return process.env.VUE_APP_FULFILLMENT_PREVIRED_API_JWT;
  }

  printInfo() {
    console.log(
      `%c fulfillment-previred-widget %c ${process.env.VUE_APP_VERSION} %c`,
      'background:#294594 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
      'background:#4faeff ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
      'background:transparent'
    ); // eslint-disable-line no-console
    console.log(`BUILD ENVIRONMENT: ${Config.buildEnvironment()}`); // eslint-disable-line no-console
    console.log(`CONFIGURED ENVIRONMENT: ${this.environment}`); // eslint-disable-line no-console
    console.log(`PREVIRED_API: ${this.previredApiUrl(false)}`); // eslint-disable-line no-console
    console.log(`KONG_API: ${this.kongUrl()}`); // eslint-disable-line no-console
    console.log(`TRACE_API: ${this.kafkaRestUrl(false)}`); // eslint-disable-line no-console
  }
}
