<template>
  <div class="previred">
    <PreviredAcepta
      v-if="isAcepta"
      :rut="upperClientRut"
      :executive-rut="upperExecutiveRut"
      :response-type="responseType"
      :dummy-mode="dummyMode"
      :kong-token="kongToken"
      :kong-client-id="innerClientId"
      :kong-client-secret="innerClientSecret"
      :environment="environment"
      :previredAPI="previredAPI"
      :trace-client="traceService('Validation.Acepta')"
      :trace-id="traceId"
      :channel-key="channelKey"
      @previredAcepta="$emit('previred', $event)"
    ></PreviredAcepta>
    <PreviredEquifax
      v-if="isKBA"
      :rut="upperClientRut"
      :response-type="responseType"
      :provider="authMethod"
      :time-limit-mode="timeLimitMode"
      :color="color"
      :dummy-mode="dummyMode"
      :kong-token="kongToken"
      :kong-client-id="innerClientId"
      :kong-client-secret="innerClientSecret"
      :environment="environment"
      :previredAPI="previredAPI"
      :trace-client="traceService('Validation.Equifax')"
      :trace-id="traceId"
      :channel-key="channelKey"
      @auth="$emit('auth', $event)"
      @previredEquifax="$emit('previred', $event)"
      @goBack="$emit('goBack')"
    ></PreviredEquifax>
    <PreviredRemoteAssisted
      v-if="isRemoteAssisted"
      :rut="upperClientRut"
      :response-type="responseType"
      :color="color"
      :executiveRut="upperExecutiveRut"
      :authMethod="isRemoteAssistedFacial && authMethod"
      :dummy-mode="dummyMode"
      :kong-token="kongToken"
      :kong-client-id="innerClientId"
      :kong-client-secret="innerClientSecret"
      :environment="environment"
      :previredService="previredService"
      :trace-service="traceService('Validation.Remote')"
      :trace-id="traceId"
      :channel-key="channelKey"
      @previredRemoteAssisted="$emit('previred', $event)"
      @goBack="$emit('goBack')"
    ></PreviredRemoteAssisted>
    <PreviredCICUERemoteAssisted
      v-if="isCICUERemoteAssisted"
      :rut="upperClientRut"
      :response-type="responseType"
      :color="color"
      :executiveRut="upperExecutiveRut"
      :auth-method="isCICUERemoteAssisted && authMethod"
      :dummy-mode="dummyMode"
      :kong-token="kongToken"
      :kong-client-id="innerClientId"
      :kong-client-secret="innerClientSecret"
      :environment="environment"
      :previredService="previredService"
      :trace-service="traceService('Validation.Remote')"
      :trace-id="traceId"
      :channel-key="channelKey"
      :client-email="clientEmail"
      :timeout-ci="timeoutCi"
      :timeout-cue="timeoutCue"
      :strict-name-validation="strictNameValidation"
      @previredCICUERemoteAssisted="$emit('previred', $event)"
      @goBack="$emit('goBack')"
    ></PreviredCICUERemoteAssisted>
    <PreviredFacialRecognition
      v-if="isFacialRecognition"
      :rut="upperClientRut"
      :response-type="responseType"
      :provider="authMethod"
      :time-limit-mode="timeLimitMode"
      :color="color"
      :dummy-mode="dummyMode"
      :kong-token="kongToken"
      :kong-client-id="innerClientId"
      :kong-client-secret="innerClientSecret"
      :environment="environment"
      :previredAPI="previredAPI"
      :previredService="previredService"
      :trace-client="traceService('Validation.FacialRecognition')"
      :trace-id="traceId"
      :channel-key="channelKey"
      @auth="$emit('auth', $event)"
      @previredFacialRecognition="$emit('previred', $event)"
      @goBack="$emit('goBack')"
    ></PreviredFacialRecognition>
  </div>
</template>

<script>
import Config from "@/services/config";
import { DummyTraceService } from "@/services/previred/trace-service-dummy";
import { KongService } from "@/services/previred/kong-service";
import { PreviredService } from "@/services/previred/previred-service";
import PreviredAcepta from "@/components/PreviredAcepta";
import PreviredEquifax from "@/components/PreviredEquifax";
import PreviredRemoteAssisted from "@/components/PreviredRemoteAssisted";
import PreviredCICUERemoteAssisted from "@/components/PreviredCICUERemoteAssisted.vue";
import PreviredFacialRecognition from "@/components/PreviredFacialRecognition";
import { PreviredAPI } from "@/services/previred-api";
import { TraceService } from "@/services/previred/trace-service";

export const RESPONSE_TYPES = {
  TOKEN: "TOKEN",
  DATA: "DATA"
};

export const AUTH_METHODS = {
  ACEPTA: "ACEPTA",
  EQUIFAX: "EQUIFAX",
  SINACOFI: "SINACOFI",
  REMOTE_ASSISTED: "REMOTE_ASSISTED",
  REMOTE_ASSISTED_FACIAL: "REMOTE_ASSISTED_FACIAL",
  FACIAL_RECOGNITION: "FACIAL_RECOGNITION",
  CICUE: "CICUE"
};

export default {
  // eslint-disable-next-line
  name: "Previred",
  components: {
    PreviredAcepta,
    PreviredEquifax,
    PreviredRemoteAssisted,
    PreviredCICUERemoteAssisted,
    PreviredFacialRecognition
  },
  props: {
    rut: { type: String, required: true },
    clientEmail: { type: String, required: false },
    clientName: { type: String, required: false },
    executiveRut: { type: String, required: false },
    executiveName: { type: String, required: false },
    authMethod: { type: String, default: AUTH_METHODS.ACEPTA },
    responseType: { type: String, default: RESPONSE_TYPES.TOKEN },
    timeLimitMode: { type: String, default: "PER_ANSWER" },
    color: { type: String, default: "#0091DA" },
    dummyMode: { type: Boolean, default: false },
    traceId: { type: String, default: TraceService.createTraceId() },
    kongToken: { type: String },
    kongClientId: { type: String },
    kongClientSecret: { type: String },
    environment: { type: String, default: Config.buildEnvironment() },
    // for testing purposes
    customPreviredService: { type: Object, required: false },
    // deprecated
    clientId: { type: String }, // use kongClientId instead
    clientSecret: { type: String }, // use kongClientSecret instead
    channelKey: { type: String },
    timeoutCi: { type: Number },
    timeoutCue : {type: Number },
    strictNameValidation: { type: Boolean, required: false }
  },
  data() {
    return {
      config: new Config(this.environment)
    };
  },
  created() {
    this.config.printInfo();
  },
  computed: {
    upperClientRut() {
      return this.rut && this.rut.toUpperCase();
    },
    upperExecutiveRut() {
      return this.executiveRut && this.executiveRut.toUpperCase();
    },
    isAcepta() {
      return this.authMethod === AUTH_METHODS.ACEPTA;
    },
    isKBA() {
      return this.authMethod === AUTH_METHODS.EQUIFAX || this.authMethod === AUTH_METHODS.SINACOFI;
    },
    isRemoteAssisted() {
      return (
        this.authMethod === AUTH_METHODS.REMOTE_ASSISTED ||
        this.authMethod === AUTH_METHODS.REMOTE_ASSISTED_FACIAL
      );
    },
    isRemoteAssistedFacial() {
      return this.authMethod === AUTH_METHODS.REMOTE_ASSISTED_FACIAL;
    },
    isCICUERemoteAssisted() {
      return this.authMethod === AUTH_METHODS.CICUE;
    },
    isFacialRecognition() {
      return this.authMethod === AUTH_METHODS.FACIAL_RECOGNITION;
    },
    innerClientId() {
      return this.kongClientId || this.clientId || undefined;
    },
    innerClientSecret() {
      return this.kongClientSecret || this.clientSecret || undefined;
    },
    kongService() {
      if (this.kongToken) {
        return KongService.fromToken(this.kongToken);
      } else if (this.innerClientId && this.innerClientSecret) {
        return KongService.fromClientCredentials(
          this.config.kongUrl(),
          this.innerClientId,
          this.innerClientSecret
        );
      } else if (this.dummyMode) {
        return KongService.fromToken("dummyModeToken");
      } else {
        throw new Error(
          "Missing parameter: either kong token or kong token credentials must be defined, found none"
        );
      }
    },
    previredAPI() {
      return new PreviredAPI(
        this.config.previredApiUrl(),
        this.kongService,
        this.dummyMode,
        this.traceId
      );
    },
    previredService() {
      if (this.customPreviredService) {
        return this.customPreviredService;
      } else {
        return new PreviredService(
          this.kongService,
          this.traceService("PreviredOperation"),
          this.config.previredApiUrl()
        );
      }
    }
  },
  methods: {
    traceService(operation) {
      if (this.dummyMode) {
        return new DummyTraceService();
      } else {
        return new TraceService(this.kongService, this.config.kafkaRestUrl(), {
          traceId: this.traceId,
          channel: "previred-widget",
          company: "cuprum",
          operation: operation,
          clientId: this.clientRut
        });
      }
    }
  }
};
</script>

<style src="fulfillment-cuprum-auth-widget/dist/fulfillment-cuprum-auth-widget.css"></style>
