<template>
  <div class="previred-facial-recognition">
    <div class="sw-step">
      <div class="auth" v-if="showAuthWidget">
        <FulfillmentAuthentication
          :rut="rut"
          :company="'cuprum'"
          :method="'facialRecognition'"
          :dummy-mode="dummyMode"
          :trace-id="traceId"
          :kong-token="kongToken"
          :kong-client-id="kongClientId"
          :kong-client-secret="kongClientSecret"
          :environment="environment"
          :facial-timer="facialTimer"
          :channel-key="channelKey"
          @auth="onAuthentication"
          @goBack="$emit('goBack')"
        ></FulfillmentAuthentication>
      </div>
      <div class="previred" v-else>
        <Result
          v-if="isPollingOn && !hasErrors"
          title="Tu identidad ha sido validada"
          body="Por favor espera, estamos obteniendo tus datos de afiliado"
        >
          <img
            class="hourglass"
            slot="image"
            src="https://static.dx.principal.cl/fulfillment/assets/previred/hourglass.png"
            alt="waiting"
          />
        </Result>
        <Result
          v-if="!isPollingOn && !hasErrors"
          title="Tus datos han sido obtenidos"
          body="Ya puedes migrar a CuprumAFP"
        >
          <img
            slot="image"
            src="https://static.dx.principal.cl/fulfillment/assets/auth/kba/success_image.svg"
            alt="success_image"
          />
        </Result>
        <Result
          v-if="hasErrors"
          title="No es posible verificar tu información"
          body="Por la seguridad de tu información no es posible continuar."
        >
          <img
            slot="image"
            src="https://static.dx.principal.cl/fulfillment/assets/auth/kba/error_image.svg"
            alt="error_image"
          />
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import { FFResultEvent } from "@/services/previred/structure-response";
import FulfillmentAuthentication from "fulfillment-cuprum-auth-widget";
import Result from "./ResultComponent";


const RESULT_EVENT = "previredFacialRecognition";

export default {
  name: "PreviredFacialRecognition",
  components: { FulfillmentAuthentication, Result },
  props: {
    rut: { type: String, required: true },
    dummyMode: { type: Boolean, default: false },
    kongToken: { type: String },
    kongClientId: { type: String },
    kongClientSecret: { type: String },
    previredAPI: { type: Object, required: true },
    previredService: { type: Object, required: true },
    traceClient: { type: Object, required: true },
    traceId: { type: String, required: true },
    environment: { type: String },
    facialTimer: { type: Number, default: 3 },
    channelKey: { type: String }
  },
  data() {
    return {
      showAuthWidget: true,
      isPollingOn: false,
      hasErrors: false
    };
  },
  methods: {
    onAuthentication(authResponse) {
      this.showAuthWidget = false;
      this.$emit("auth", authResponse);
      if (authResponse.result) {
        if (authResponse.result.status === "APPROVED" && authResponse.result.certificate) {
          this.previredService
            .selfValidation({ certificate: authResponse.result.certificate })
            .then(response => {
              if (response.status === "CONFIRMED") {
                this.pollToken(response.transactionId, Date.now());
              } else {
                this.hasErrors = true;
                const error = {
                  reason: "INVALID_TRANSACTION_STATUS",
                  message: `Status: ${response.status}`,
                  source: "PreviredWidget"
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(error, this.traceId));
              }
            })
            .catch(err => {
              this.hasErrors = true;
              const error = {
                reason: "FAILURE_CREATING_TRANSACTION",
                message: err.message,
                source: "PreviredWidget"
              };
              this.$emit(RESULT_EVENT, FFResultEvent.failure(error, this.traceId));
            });
        } else {
          this.hasErrors = true;
          let errorResponse = {
            reason: "AUTH_VALIDATION_FAILED",
            message: "Status response : " + authResponse.result.status
          };
          this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
        }
      } else {
        this.hasErrors = true;
        let errorResponse = authResponse;
        if (errorResponse.error) {
          if (errorResponse.error.source) {
            errorResponse.source = "AuthWidget";
          }
        } else {
          errorResponse = FFResultEvent.failure(
            {
              reason: "AUTH_VALIDATION_ERROR",
              message: "UNEXPECTED ERROR",
              source: "AuthWidget"
            },
            this.traceId
          );
        }
        this.$emit(RESULT_EVENT, errorResponse);
      }
    },
    pollToken(transactionId, startTime) {
      this.isPollingOn = true;
      let timePassed = Date.now() - startTime;
      if (timePassed < process.env.VUE_APP_PREVIRED_POLLING_MAX_TIME) {
        setTimeout(() => {
          this.previredService
            .getToken(transactionId)
            .then(response => {
              this.tokenStatus = response.status;
              if (this.tokenStatus === "UNCONFIRMED" || this.tokenStatus === "PENDING") {
                this.pollToken(transactionId, startTime);
              } else if (this.tokenStatus === "OK") {
                this.isPollingOn = false;
                const successResponse = {
                  transactionId: transactionId,
                  token: response.token,
                  clientIP: response.clientIP,
                  executiveIP: response.executiveIP,
                  clientProvider: response.clientProvider
                };
                this.$emit(RESULT_EVENT, FFResultEvent.success(successResponse, this.traceId));
              } else {
                this.hasErrors = true;
                this.isPollingOn = false;
                const errorResponse = {
                  reason:
                    this.tokenStatus === "EXPIRED" ? "TRANSACTION_EXPIRED" : "TRANSACTION_ERROR",
                  message: "Status in token response : " + response.status
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              }
            })
            .catch(error => {
              this.pollingErrorRetries -= 1;
              if (this.pollingErrorRetries > 0) {
                this.pollToken(transactionId, startTime);
              } else {
                this.hasErrors = true;
                this.isPollingOn = false;
                const errorResponse = {
                  reason: "PREVIRED_API_ERROR",
                  message: error.message,
                  source: "PreviredAPI"
                };
                this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
              }
            });
        }, this.pollingIntervalInMillis);
      } else {
        this.hasErrors = true;
        this.isPollingOn = false;
        const error = {
          reason: "POLLING_TIMEOUT",
          message: "Polling exceeds the maximum time allowed"
        };
        this.$emit(RESULT_EVENT, FFResultEvent.failure(error, this.traceId));
      }
    }
  }
};
</script>

<style scoped>
.sw-step {
  align-items: center;
  height: 100%;
  width: 100%;
  color: #484848;
  font-size: 0.9rem;
}
.errors {
  color: red;
}

img.hourglass {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
img.success {
  animation: expand 2s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes expand {
  0% {
    transform: width 110%, height 110%;
  }
  50% {
    transform: width 110%, height 110%;
  }
  100% {
    transform: width 110%, height 110%;
  }
}
</style>
