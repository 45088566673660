<template>

  <div class="previred-acepta">

    <ProgressMark circle circle-line-top :status="getFirstPMStatus()">

      <div class="sw-step">
         {{ isExecutiveValidated() ? "Ejecutivo validado exitosamente" : "" }}
        <div class="auth">

          <FulfillmentAuthentication
            v-if="isInit()"
            :rut="executiveRut"
            @click="clearErrors"
            :oti-mode="true"
            :method="'fingerprint'"
            :button-enabled="true"
            :button-label="'Validar ejecutivo'"
            :dummy-mode="dummyMode"
            :trace-id="traceId"
            :kong-token="kongToken"
            :kong-client-id="kongClientId"
            :kong-client-secret="kongClientSecret"
            :environment="environment"
            :channel-key="channelKey"
            @auth="executiveValidation"
          ></FulfillmentAuthentication>

        </div>

      </div>

    </ProgressMark>

    <ProgressMark circle-line-bottom :status="getFirstPMStatus()"></ProgressMark>

    <ProgressMark circle circle-line-top :status="getLastPMStatus()">

      <div class="sw-step">
         {{ isClientValidated() ? "Cliente validado exitosamente" : "" }}
        <div class="auth">

          <Spinner v-if="isPollingOn" :message="'Solicitando información a PREVIRED'" />

          <FulfillmentAuthentication
            v-if="isExecutiveValidated() && !isClientValidated() && !isPollingOn"
            :rut="rut"
            @click="clearErrors"
            :oti-mode="true"
            :method="'fingerprint'"
            :button-enabled="true"
            :button-label="'Validar cliente'"
            :dummy-mode="dummyMode"
            :trace-id="traceId"
            :kong-token="kongToken"
            :kong-client-id="kongClientId"
            :kong-client-secret="kongClientSecret"
            :environment="environment"
            :channel-key="channelKey"
            @auth="clientValidation"
          ></FulfillmentAuthentication>

        </div>

      </div>

    </ProgressMark>

    <div v-if="hasErrors" class="errors">{{ errorMessage }}</div>

  </div>

</template>

<script>
import FulfillmentAuthentication from "fulfillment-cuprum-auth-widget";
import { FFResultEvent } from "@/services/previred/structure-response";
import ProgressMark, { statuses, checkStatusMethods } from "@/components/ProgressMark";
import Spinner from "@/components/Spinner";
import { VALIDATION } from "@/services/previred-api";
import { createLogger } from "@/services/logger";

const logger = createLogger("PreviredWidget");

export const ACEPTA_STATUS = {
  INIT: "INIT",
  EXECUTIVE_VALIDATED: "EXECUTIVE_VALIDATED",
  CLIENT_VALIDATED: "CLIENT_VALIDATED"
};

const PREVIRED_ACEPTA_STEP = "PreviredAcepta";
const RESULT_EVENT = "previredAcepta";

export default {
  name: PREVIRED_ACEPTA_STEP,
  components: { ProgressMark, Spinner, FulfillmentAuthentication },
  props: {
    rut: { type: String, required: true },
    executiveRut: { type: String, required: false },
    responseType: { type: String, default: "TOKEN" },
    dummyMode: { type: Boolean, default: false },
    kongToken: { type: String },
    kongClientId: { type: String },
    kongClientSecret: { type: String },
    previredAPI: { type: Object, required: true },
    traceClient: { type: Object, required: true },
    traceId: { type: String, required: true },
    environment: { type: String },
    channelKey: { type: String }
  },
  data() {
    return {
      currentAceptaStatus: ACEPTA_STATUS.INIT,
      client: {},
      executive: {},
      isPollingOn: false,
      hasErrors: false,
      errorMessage: ""
    };
  },
  methods: {
    ...checkStatusMethods,
    isInit() {
      return ACEPTA_STATUS.INIT === this.currentAceptaStatus;
    },
    isExecutiveValidated() {
      return ACEPTA_STATUS.INIT !== this.currentAceptaStatus;
    },
    isClientValidated() {
      return ACEPTA_STATUS.CLIENT_VALIDATED === this.currentAceptaStatus;
    },
    getFirstPMStatus() {
      switch (this.currentAceptaStatus) {
        case ACEPTA_STATUS.INIT:
          return this.hasErrors ? statuses.ERROR : statuses.DOING;
        default:
          return statuses.DONE;
      }
    },
    getLastPMStatus() {
      switch (this.currentAceptaStatus) {
        case ACEPTA_STATUS.INIT:
          return statuses.TODO;
        case ACEPTA_STATUS.EXECUTIVE_VALIDATED:
          return this.hasErrors ? statuses.ERROR : statuses.DOING;
        default:
          return statuses.DONE;
      }
    },
    clearErrors() {
      this.hasErrors = false;
      this.errorMessage = "";
    },
    executiveValidation(authEvent) {
      const authResponse = authEvent;
      if (authResponse.result.userId === this.executiveRut) {
        logger.log(`authResponse: ${JSON.stringify(authResponse)}`);
        if (authResponse.status === "OK") {
          this.currentAceptaStatus = ACEPTA_STATUS.EXECUTIVE_VALIDATED;
          logger.log("Executive authentication success");
          this.traceClient.success(
            `${PREVIRED_ACEPTA_STEP}.executiveValidation`,
            "Executive validation succeded",
            JSON.stringify(authResponse)
          );
          this.executive.rut = this.executiveRut;
          this.loadAuthResponse(this.executive, authResponse.result);
        } else {
          logger.log("Executive authentication failure", authResponse.error);
          this.traceClient.error(
            `${PREVIRED_ACEPTA_STEP}.executiveValidation`,
            "Executive validation failed",
            authResponse.error
          );
          let errorResponse = authResponse;
          if (errorResponse.error) {
            if (!errorResponse.error.source) {
              errorResponse.error.source = "AuthWidget";
            }
          } else {
            errorResponse = FFResultEvent.failure(
              {
                reason: "EXECUTIVE_AUTH_FAILURE",
                message: `Executive authentication failure ${authResponse.error}`,
                source: "AuthWidget"
              },
              this.traceId
            );
          }
          this.hasErrors = true;
          this.errorMessage = "Error en la validación del ejecutivo";
          this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
        }
      }
    },
    clientValidation(authEvent) {
      const authResponse = authEvent;
      if (authResponse.result.userId === this.rut) {
        logger.log(`authResponse: ${JSON.stringify(authResponse)}`);
        if (authResponse.status === "OK") {
          logger.log("Client authentication success");
          this.traceClient.success(
            `${PREVIRED_ACEPTA_STEP}.clientValidation`,
            "Client validation succeded",
            JSON.stringify(authResponse)
          );
          this.client.rut = this.rut;
          this.loadAuthResponse(this.client, authResponse.result);
          const previredAuthResponse = {
            status: "OK",
            client: this.client,
            executive: this.executive
          };
          this.previredAPIRequest(previredAuthResponse);
        } else {
          logger.log("Client authentication failure", authResponse.error);
          this.traceClient.error(
            `${PREVIRED_ACEPTA_STEP}.clientValidation`,
            "Client validation failed",
            authResponse.error
          );
          let errorResponse = authResponse;
          if (errorResponse.error) {
            if (!errorResponse.error.source) {
              errorResponse.error.source = "AuthWidget";
            }
          } else {
            errorResponse = FFResultEvent.failure(
              {
                reason: "CLIENT_AUTH_FAILURE",
                message: `Client authentication failure ${authResponse.error}`,
                source: "AuthWidget"
              },
              this.traceId
            );
          }
          this.hasErrors = true;
          this.errorMessage = "Error en la validación del cliente";
          this.$emit(RESULT_EVENT, errorResponse);
        }
      }
    },
    previredAPIRequest(previredAuthResponse) {
      this.isPollingOn = true;
      this.previredAPI
        .getPreviredResponse(previredAuthResponse, VALIDATION.ACEPTA_BIOMETRIC)
        .then(response => {
          this.traceClient.success(
            `${PREVIRED_ACEPTA_STEP}.previredAPIRequest`,
            "Previred succesfully response",
            JSON.stringify(response)
          );
          this.isPollingOn = false;
          this.currentAceptaStatus = ACEPTA_STATUS.CLIENT_VALIDATED;
          this.$emit(RESULT_EVENT, FFResultEvent.success(response, this.traceId));
        })
        .catch(error => {
          this.traceClient.error(
            `${PREVIRED_ACEPTA_STEP}.previredAPIRequest`,
            "Catch error",
            JSON.stringify(error)
          );
          const errorResponse = {
            reason: "CATCHED_ERROR_DURING_PREVIRED_API_REQUEST",
            message: error.message
          };
          this.isPollingOn = false;
          this.hasErrors = true;
          this.errorMessage = "Error en la respuesta de PREVIRED";
          this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
        });
    },
    loadAuthResponse(targetObj, authResult) {
      targetObj.auditCode = authResult.auditCode;
      targetObj.url = authResult.resultUrl;
      targetObj.name = authResult.name;
      targetObj.lastName = authResult.lastName;
      targetObj.motherLastName = authResult.motherLastName;
    }
  }
};
</script>

<style scoped>
.sw-step {
  align-items: center;
  height: 100%;
  color: #484848;
  font-size: 0.9rem;
}
.errors {
  color: red;
}
</style>

