<template>
  <div class="previred-equifax">
    <div class="sw-step">
      <div class="auth" v-if="showAuthWidget">
        <FulfillmentAuthentication
          :rut="rut"
          :company="'cuprum'"
          :oti-mode="false"
          :method="'questions'"
          :provider="provider"
          :time-limit-mode="timeLimitMode"
          :color="color"
          :button-enabled="true"
          :button-label="'Validar ejecutivo'"
          :dummy-mode="dummyMode"
          :trace-id="traceId"
          :kong-token="kongToken"
          :kong-client-id="kongClientId"
          :kong-client-secret="kongClientSecret"
          :environment="environment"
          :channel-key="channelKey"
          @auth="onAuthentication"
          @goBack="$emit('goBack')"
        ></FulfillmentAuthentication>
      </div>
      <div class="previred" v-else>
        <Result
          v-if="isPollingOn && !hasErrors"
          title="Tu identidad ha sido validada"
          body="Por favor espera, estamos obteniendo tus datos de afiliado"
        >
          <img
            class="hourglass"
            slot="image"
            src="https://static.dx.cuprum.cl/fulfillment/assets/previred/hourglass.png"
            alt="waiting"
          />
        </Result>
        <Result
          v-if="!isPollingOn && !hasErrors"
          title="Tus datos han sido obtenidos"
          body="Ya puedes migrar a CuprumAFP"
        >
          <img
            slot="image"
            src="https://static.dx.cuprum.cl/fulfillment/assets/auth/kba/success_image.svg"
            alt="success_image"
          />
        </Result>
        <Result
          v-if="hasPreviredError"
          title="No es posible verificar tu información"
          body="Por la seguridad de tu información no es posible continuar."
        >
          <img
            slot="image"
            src="https://static.dx.cuprum.cl/fulfillment/assets/auth/kba/error_image.svg"
            alt="error_image"
          />
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import FulfillmentAuthentication from "fulfillment-cuprum-auth-widget";
import { FFResultEvent } from "@/services/previred/structure-response";
import Result from "./ResultComponent";
import { VALIDATION } from "@/services/previred-api";


const TRACE_STEP = "PreviredEquifax";
const RESULT_EVENT = "previredEquifax";

export default {
  name: "PreviredEquifax",
  components: { FulfillmentAuthentication, Result },
  props: {
    rut: { type: String, required: true },
    responseType: { type: String, default: "TOKEN" },
    dummyMode: { type: Boolean, default: false },
    color: { type: String, default: "#0091DA" },
    kongToken: { type: String },
    kongClientId: { type: String },
    kongClientSecret: { type: String },
    previredAPI: { type: Object, required: true },
    traceClient: { type: Object, required: true },
    traceId: { type: String, required: true },
    environment: { type: String },
    provider: { type: String, default: "EQUIFAX" },
    timeLimitMode: { type: String, default: "PER_ANSWER" },
    channelKey: { type: String }
  },
  data() {
    return {
      showAuthWidget: true,
      isPollingOn: false,
      hasErrors: false,
      authTransactionId: "",
      hasPreviredError: false
    };
  },
  methods: {
    onAuthentication(authResponse) {
      this.$emit("auth", authResponse);
      const traceStep = `${TRACE_STEP}.onAuthentication`;
      if (
        authResponse.status !== "OK" ||
        !authResponse.result ||
        authResponse.result.status !== "APPROVED"
      ) {
        this.hasErrors = true;
        this.traceClient.error(traceStep, "authentication failure", JSON.stringify(authResponse));
        let errorResponse = authResponse;
        if (errorResponse.error) {
          if (errorResponse.error.source) {
            errorResponse.source = "AuthWidget";
          }
        } else {
          errorResponse = FFResultEvent.failure(
            {
              reason: "AUTHENTICATION_FAILURE",
              message: "Response has an invalid status",
              source: "AuthWidget"
            },
            this.traceId
          );
        }
        this.$emit(RESULT_EVENT, errorResponse);
      } else if (!authResponse.result.previredVerification) {
        this.hasErrors = true;
        this.traceClient.error(
          traceStep,
          "missing previred verification data",
          JSON.stringify(authResponse)
        );
        const errorResponse = {
          reason: "AUTHENTICATION_FAILURE",
          message: "Previred Verification Data is missing in auth response",
          source: "PreviredWidget"
        };
        this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
      } else {
        this.showAuthWidget = false;
        this.traceClient.success(
          `${TRACE_STEP}.onAuthentication`,
          "auth succeded",
          JSON.stringify(authResponse)
        );
        this.authTransactionId = authResponse.result.transactionId;
        this.startPreviredTokenPolling(authResponse.result.previredVerification);
      }
    },
    startPreviredTokenPolling(previredVerification) {
      const traceStep = `${TRACE_STEP}.startPreviredTokenPolling`;
      this.isPollingOn = true;
      this.traceClient.info(traceStep, "Start polling for token", `{client: ${this.rut}}`);
      var sufix = this.provider === "EQUIFAX" ? previredVerification.code : "";
      this.previredAPI
        .getPreviredResponse(
          {
            status: "OK",
            client: {
              rut: this.rut,
              auditCode: previredVerification.code,
              url: `${previredVerification.url}${sufix}`,
              name: previredVerification.name,
              lastName: previredVerification.lastName,
              motherLastName: previredVerification.motherLastName,
              user: previredVerification.userUrl,
              password: previredVerification.passUrl
            }
          },
          this.provider === VALIDATION.SINACOFI_KBA.provider
            ? VALIDATION.SINACOFI_KBA
            : VALIDATION.EQUIFAX_KBA
        )
        .then(response => {
          const responseEvent = {
            transactionId: response.transactionId,
            token: response.token,
            clientIP: response.clientIP,
            executiveIP: response.executiveIP,
            clientProvider: response.clientProvider
          };
          let structuredResponse = FFResultEvent.success(responseEvent, this.traceId);
          this.traceClient.success(traceStep, "Token obtained", JSON.stringify(structuredResponse));
          this.$emit(RESULT_EVENT, structuredResponse);
        })
        .catch(err => {
          const errorMessage = "Previred failed to give a token";
          const responseEvent = {
            status: "NOK",
            authTransactionId: this.authTransactionId,
            error: err
          };
          this.traceClient.error(traceStep, errorMessage, JSON.stringify(responseEvent));
          this.hasErrors = true;
          this.hasPreviredError = true;
          const errorResponse = {
            reason: "PREVIRED_FAILED_TO_RETRIVE_TOKEN",
            message: err.message,
            source: "PreviredWidget"
          };
          this.$emit(RESULT_EVENT, FFResultEvent.failure(errorResponse, this.traceId));
        })
        .finally(() => {
          this.isPollingOn = false;
        });
    }
  }
};
</script>

<style scoped>
.sw-step {
  align-items: center;
  height: 100%;
  width: 100%;
  color: #484848;
  font-size: 0.9rem;
}
.errors {
  color: red;
}

img.hourglass {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 2s;
  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
img.success {
  animation: expand 2s;
  animation-iteration-count: 1;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes expand {
  0% {
    transform: width 110%, height 110%;
  }
  50% {
    transform: width 110%, height 110%;
  }
  100% {
    transform: width 110%, height 110%;
  }
}
</style>
