<template>
  <div class="fulfillment-question-main">
    <h1 class="fulfillment-title">{{title}}</h1>
    <p class="fulfillment-paragrapf">{{body}}</p>
    <div class="fulfillment-img-container">
      <slot name="image"></slot>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line
  name: "Result",
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.fulfillment-question-main {
  color: #4a4a4a;
  padding: 15px;
  min-width: 200px;
  max-width: 600px;
  margin: auto;
}
.fulfillment-title {
  font-weight: lighter;
  font-size: 28px;
}
.fulfillment-paragraph {
  font-size: 18px;
}
.fulfillment-img-container {
  text-align: center;
}
.fulfillment-img-container img {
  display: inline;
  max-width: 80vw;
}
</style>
