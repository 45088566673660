const FAKED_VALIDATE_ASSISTED = {
  data: {
    transactionId: "asdsa8-asdas23-das2371"
  }
};

const FAKED_GET_TOKEN_RESPONSE = {
  data: {
    status: "OK",
    token: "Fake Token Response",
    clientIP: "127.0.0.1",
    executiveIP: "127.0.0.1",
    clientProvider: "EQUIFAX"
  }
};

class PreviredAPIClientMock {
  submitPreviredDataRequest() {
    return new Promise(rs => rs(FAKED_VALIDATE_ASSISTED));
  }

  getPreviredToken() {
    return new Promise(rs => rs(FAKED_GET_TOKEN_RESPONSE));
  }
}

export { PreviredAPIClientMock as default };
