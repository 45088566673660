<template>
  <div class="sw-row">
    <div class="sw-progress-cell">
      <div class="circle-line-top" :class="statusCSS" v-if="!hidden && circle && circleLineTop"></div>
      <div class="main-mark" :class="shapeCSS + ' ' + statusCSS" v-if="!hidden"></div>
      <div class="circle-line-bottom" v-if="!hidden && circle && circleLineBottom"></div>
    </div>
    <div class="sw-content-cell">
      <slot></slot>
    </div>
  </div>
</template>

<script>

// ProgressMark could be in any of the four statuses listed below.
export const statuses = {
  TODO: "TODO",
  DOING: "DOING",
  DONE: "DONE",
  ERROR: "ERROR"
}

export const checkStatusMethods = {
  isTODO: (status) => status === statuses.TODO,
  isDOING: (status) => status === statuses.DOING,
  isDONE: (status) => status === statuses.DONE,
  isERROR: (status) => status === statuses.ERROR,
}

export default {
  name: 'ProgressMark',
  props: {
    circle: {type: Boolean, default: false},
    circleLineTop: {type: Boolean, default: false},
    circleLineBottom: {type: Boolean, default: false},
    status: { type: String, default: statuses.TODO },
    hidden: {type: Boolean, default: false}
  },
  computed: {
    statusCSS() {
      switch (this.status) {
        case statuses.DOING: return "is-loading"
        case statuses.DONE: return "is-completed"
        case statuses.ERROR: return "has-errors"
      }
      return ""
    },
    shapeCSS() {
      return `${this.circle ? "circle" : "line"}`
    }
  }
}
</script>

<style scoped>
.sw-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}
.sw-progress-cell {
  flex-basis: 36px;
  min-width: 36px;
}
.sw-content-cell {
  padding-left: 1rem;
  flex-grow: 1;
}

.circle {
  background: #bbbbbb;
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
}
.circle.is-loading {
  background: #0091da;
  width: 30px;
  height: 30px;
  margin: auto;
  background-image: url(https://static.dx.principal.cl/fulfillment/assets/loading.png);
  background-repeat: no-repeat;
  background-position: center;
}
.circle.is-completed {
  background: #00c6b7;
  width: 30px;
  height: 30px;
  margin: auto;
  background-image: url(https://static.dx.principal.cl/fulfillment/assets/check.png);
  background-repeat: no-repeat;
  background-position: center;
}
.circle.has-errors {
  background: #ffcc00;
  width: 30px;
  height: 30px;
  margin: auto;
  background-image: url(https://static.dx.principal.cl/fulfillment/assets/alert.png);
  background-repeat: no-repeat;
  background-position: center;
}
.circle-line-bottom {
  background: #bbbbbb;
  width: 4px;
  margin: 0;
  padding: 0;
  margin-top: -12px;
  margin-left: 16px;
  height: 12px;
}
.circle-line-top {
  background: #bbbbbb;
  width: 4px;
  margin: 0;
  padding: 0;
  margin-bottom: -12px;
  margin-left: 16px;
  height: 12px;
}
.circle-line-top.is-completed {
  background: #00c6b7;
}

.circle-line-top.has-error {
  background: #ffcc00;
}

.line {
  background: #bbbbbb;
  width: 4px;
  margin: auto;
  height: 100%;
  min-height: 7rem;
}
.line.is-loading {
  background: #0091da;
}
.line.is-completed {
  background: #00c6b7;
}
.line.has-error { 
  background: #ffcc00;
}
</style>
